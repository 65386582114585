import { colors } from '@blissbook/ui/branding'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import _isFinite from 'lodash/isFinite'
import last from 'lodash/last'
import moment from 'moment'

function findByScore<T extends { score: number }>(
  collection: T[],
  score: number,
) {
  if (!_isFinite(score)) return
  return collection.reduce((result, entry) => {
    if (score >= entry.score) result = entry
    return result
  })
}

export type Grade = {
  letter: string
  color: string
  score?: number
}

export type Risk = {
  color: string
  icon: IconProp
  level: string
}

const grayColor = colors['gray-500']
const greenColor = colors['green-500']
const yellowColor = colors['sunshine-500']
const redColor = colors['red-500']

const riskNotAvailable = { color: grayColor, level: 'not-available' }
const noRisk = { score: 10, color: greenColor, icon: 'check' }
const lowRisk = { score: 5, color: greenColor, icon: ['far', 'circle'] }
const mediumRisk = { score: 3, color: yellowColor, icon: 'square' }
const highRisk = { score: 0, color: redColor, icon: 'exclamation-triangle' }

const risks = [noRisk, lowRisk, mediumRisk, highRisk].reverse()

export const getRiskFromScore = (score: number) =>
  findByScore(risks, score) || riskNotAvailable

export function isSignatureOverdue(signatureDueAt: Date | string) {
  return moment(signatureDueAt).valueOf() < moment().valueOf()
}

export function determineRisk(recipient: IHandbookRecipient) {
  const { canView, hasValidSignature, score, signatureDueAt } = recipient

  // If can't view, unknown risk
  if (!canView) {
    return riskNotAvailable
  }
  // Show medium risk if recipient still has time to sign
  if (
    !hasValidSignature &&
    signatureDueAt &&
    !isSignatureOverdue(signatureDueAt)
  ) {
    return mediumRisk
  }

  return getRiskFromScore(score)
}

export const gradeNotAvailable: Grade = {
  letter: 'N/A',
  color: grayColor,
  score: undefined,
}

const grades = [
  { score: 9, letter: 'A', color: greenColor },
  { score: 8, letter: 'B', color: greenColor },
  { score: 7, letter: 'C', color: yellowColor },
  { score: 6, letter: 'D', color: yellowColor },
  { score: 0, letter: 'F', color: redColor },
].reverse()

export const getGradeFromScore = (score: number | undefined): Grade =>
  score ? findByScore(grades, score) : gradeNotAvailable

export function getGradeFromHandbook(handbook: IHandbook): Grade {
  const { score } = handbook.statistics
  const lastSignatureRound = last(handbook.signatureRounds)
  const gradeResetAt = moment(
    lastSignatureRound?.publishedAt || handbook.publishedAt,
  ).toDate()
  const gradeResetDays = moment().diff(gradeResetAt, 'days')
  const isGradeValid = gradeResetDays >= 14
  return isGradeValid ? getGradeFromScore(score) : gradeNotAvailable
}
